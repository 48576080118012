import * as cookie from "utils/cookieMethods";

/* eslint-disable class-methods-use-this */
import { Component, h } from "preact";

import { base64decode } from "utils/base64";
import Toggle from "./Toggle";

export default class CookieBanner extends Component {
  constructor(props) {
    super();
    const cookieData = cookie.get(`privacy-settings`);

    this.state = {
      settings: this.createSettingsStateFromCookiedata(cookieData),
      settingsOpen: false,
      collapsed: true,
      showBanner:
        props.forceOpen ||
        cookieData == null ||
        cookieData.version !== props.version
    };

    window.showCookieSettings = () => {
      const newCookieData = cookie.get(`privacy-settings`);

      this.setState({
        ...this.state,
        settings: this.createSettingsStateFromCookiedata(newCookieData)
      });
      this.showSettings();
      this.showModule();
    };
  }

  createSettingsStateFromCookiedata = cookieData => [
    {
      name: `essential`,
      text: `Notwendige Cookies`,
      enabled: true,
      disabled: true
    },
    {
      name: `media`,
      text: `Media Cookies (YouTube, Vimeo)`,
      enabled: cookieData ? cookieData.media : false,
      disabled: false
    },
    {
      name: `analytics`,
      text: `Analytics (Google Analytics)`,
      enabled: cookieData ? cookieData.analytics : false,
      disabled: false
    },
    {
      name: `marketing`,
      text: `Marketing Cookies`,
      enabled: cookieData ? cookieData.marketing : false,
      disabled: false
    }
  ];

  componentDidMount = () => {
    window.jsIsInitilized.then(() => {
      this.setState({
        ...this.state,
        textHeight: this.textContainer.offsetHeight,
        settingsHeight: this.heightHelper.offsetHeight
      });
      if (this.state.showBanner) {
        this.showModule();
      }
    });
  };

  toggleSettings = () => {
    this.setState({
      ...this.state,
      settingsOpen: !this.state.settingsOpen
    });
  };

  toggleToggle = key => {
    const { settings } = this.state;
    for (const setting of settings) {
      if (setting.name === key) {
        setting.enabled = !setting.enabled;
      }
    }
    this.setState({
      ...this.state,
      settings
    });
  };

  getNecessary = () =>
    this.state.settings.map(setting => {
      setting.enabled = setting.name === `essential`;
      return setting;
    });

  getAllActive = () =>
    this.state.settings.map(setting => {
      setting.enabled = true;
      return setting;
    });

  activateAll = () => {
    const allActive = this.getAllActive();
    this.setState(state => ({
      ...state,
      settings: allActive
    }));
  };

  showModule = () => {
    this.setState({
      ...this.state,
      collapsed: false
    });
    // this.props.module.classList.remove(`collapsed`);
  };

  hideModule = () => {
    this.setState({
      ...this.state,
      collapsed: true
    });
    // this.props.module.classList.add(`collapsed`);
  };

  showSettings = () => {
    this.setState({
      ...this.state,
      settingsOpen: true
    });
  };

  saveSettings = config => {
    const settingsToApply = {
      acceptAll: () => this.getAllActive(),
      acceptCurrent: () => this.state.settings,
      acceptNecessary: () => this.getNecessary()
    }[config];

    this.setState(
      {
        ...this.state,
        settings: settingsToApply()
      },
      () => {
        this.hideModule();
        for (const category of this.state.settings) {
          if (category.enabled) {
            for (const func of window.function[category.name]) {
              if (typeof func === `function`) {
                try {
                  func();
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.warn(`[${category.name}] Error executing: `, func);
                }
              } else {
                // eslint-disable-next-line no-console
                console.warn(`[${category.name}] Is not a function: `, func);
              }
            }
          }
          window.allow[category.name] = category.enabled;
        }
        for (const category of this.state.settings) {
          if (category.name === `essential`) {
            if (category.enabled) {
              const allowState = this.state.settings.reduce((accu, current) => {
                accu[current.name] = current.enabled;
                return accu;
              }, Object());
              cookie.set(
                `privacy-settings`,
                {
                  ...allowState,
                  version: this.props.version
                },
                false
              );
            } else {
              cookie.remove(`privacy-settings`);
            }
          }
        }
      }
    );
  };

  render(props, state) {
    return (
      <div
        class={`privacy-notice-background  ${state.collapsed === true &&
          `collapsed`}`}
      >
        <div class={`privacy-notice`}>
          <div class={state.settingsOpen ? `settings-open` : ``}>
            <span class="title">{props.title}</span>
            <div
              class="text"
              style={`height: ${state.settingsOpen ? 0 : state.textHeight}px`}
              ref={textContainer => {
                this.textContainer = textContainer;
              }}
              dangerouslySetInnerHTML={{ __html: base64decode(props.text) }}
            />
            <div
              class="settings-container"
              style={`height: ${
                state.settingsOpen ? this.heightHelper.offsetHeight : 0
              }px`}
            >
              <div
                class="settings-height-helper"
                ref={heightHelper => {
                  this.heightHelper = heightHelper;
                }}
              >
                {this.state.settings.map(cookieSetting => (
                  <Toggle {...cookieSetting} callback={this.toggleToggle} />
                ))}
              </div>
            </div>
            <div class="button-container">
              {this.state.settingsOpen && (
                <button
                  class="accept"
                  onClick={() => this.saveSettings(`acceptCurrent`)}
                >
                  {props.saveSettingsText}
                </button>
              )}
              {!this.state.settingsOpen && (
                <button
                  class="accept"
                  onClick={() => this.saveSettings(`acceptAll`)}
                >
                  {props.acceptAllText}
                </button>
              )}
              <button
                class="decline"
                onClick={() => this.saveSettings(`acceptNecessary`)}
              >
                {props.closeText}
              </button>
            </div>
            {!state.settingsOpen && (
              <a class="show-more" onClick={this.toggleSettings}>
                {props.showMoreText}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
