import breakpoints from "utils/breakpoints";
import debounce from "utils/debounce";
import isRetina from "utils/isRetina";

export default function(slider) {
  function removeActiveFlag(elements) {
    elements.forEach(element => {
      if (element.classList.contains(`active`)) {
        element.classList.remove(`active`);
      }
    });
  }

  function changeActiveElement(n, elements, permanent = false) {
    removeActiveFlag(elements);
    elements[n].classList.add(`active`);
    if (permanent) {
      elements.forEach(element => {
        element.classList.remove(`permanent`);
      });
      elements[n].classList.add(`permanent`);
    }
  }

  function changeSlideToIndex(n, sliderElements, permanent = false) {
    changeActiveElement(n, sliderElements.slides, permanent);
    changeActiveElement(n, sliderElements.controls, permanent);
  }

  function changeSlideInDirection(direction, sliderElements, controlBar) {
    // direction values: 1 = right, -1 = left

    const activeElement = controlBar.querySelector(`.active`);
    let activeIndex =
      parseInt(activeElement.getAttribute(`data-index`), 10) + direction;

    if (activeIndex === -1) {
      activeIndex = sliderElements.slides.length - 1;
    } else if (activeIndex > sliderElements.slides.length - 1) {
      activeIndex = 0;
    }

    changeSlideToIndex(activeIndex, sliderElements, false);
  }

  const getSliderElements = module => {
    const slides = module.querySelectorAll(`.container > .slide`);
    const controls = module.querySelectorAll(
      `.arrow-pagination > .slider-controls > li`
    );
    const controlBar = module.querySelector(
      `.arrow-pagination > .slider-controls`
    );

    const sliderElements = {
      slides,
      controls,
      controlBar
    };

    return sliderElements;
  };

  class Interval {
    constructor(direction, sliderElements, ms = `4999`) {
      this.ival = {};
      this.direction = direction;
      this.ms = ms;
      this.sliderElements = sliderElements;
    }

    stop() {
      clearInterval(this.ival);
    }

    start() {
      if (parseInt(this.ms, 10) !== 0 && this.ms !== ``) {
        this.ival = setInterval(
          () =>
            changeSlideInDirection(
              this.direction,
              this.sliderElements,
              this.sliderElements.controlBar
            ),
          this.ms
        );
      }
    }
  }

  function resizeListener(module) {
    if (module.querySelectorAll(`.slide`).length === 0) return;
    const slides = module.querySelectorAll(`.slide`);
    const isOnlyTitle = slides[0].classList.contains(`only-title`);
    const determineSize = () => {
      if (breakpoints.extralarge()) {
        if (isRetina()) {
          return isOnlyTitle ? `bgimage15_2x_narrow` : `bgimage25_2x`;
        }
        return isOnlyTitle ? `bgimage15_narrow` : `bgimage25`;
      }
      if (breakpoints.large())
        return isOnlyTitle ? `bgimage25_narrow` : `bgimage30`;
      if (breakpoints.medium())
        return isOnlyTitle ? `bgimage30_narrow` : `bgimage45`;
      if (breakpoints.small())
        return isOnlyTitle ? `bgimage30_narrow` : `bgimage60`;
      return isOnlyTitle ? `bgimage45_narrow` : `bgimage60`;
    };
    const setBgImage = async () => {
      const size = determineSize();
      for (const slide of slides) {
        const url = slide.dataset[size];
        slide.style.backgroundImage = `url(${url})`;

        // ! Forgot that transparency existed
        // slide.style.backgroundImage = `url(${url}), ${slide.style.backgroundImage}`;
        // This will lay the new image over the old one. By doing so, we can ensure that we don't se any white flashes.
        // Nobody will resize the browser often enough that we couldn't just concatinate.
        // ! End warn

        // Solution for fetching before replace
        /*         window
          .fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const urlObject = URL.createObjectURL(blob);
            slide.style.backgroundImage = `url(${urlObject})`;
          }); */
      }
    };
    setBgImage();
    window.addEventListener(`resize`, debounce(setBgImage, this, 100));
  }

  function marginArrowElement(module) {
    switch (
      module.querySelectorAll(`.arrow-slider-controls .arrow-container`).length
    ) {
      case 3:
        module
          .querySelectorAll(`.arrow-slider-controls .arrow-container`)
          .forEach(element => {
            element.classList.add(`items-3`);
          });
        break;
      case 5:
        module
          .querySelectorAll(`.arrow-slider-controls .arrow-container`)
          .forEach(element => {
            element.classList.add(`items-5`);
          });
        break;
      default:
        module
          .querySelectorAll(`.arrow-slider-controls .arrow-container`)
          .forEach(element => {
            element.classList.add(`items-default`);
          });
        break;
    }
  }

  function initAnimationDuration(module, s) {
    const elements = module.querySelectorAll(
      `.slider-controls > li > .bottom-line`
    );

    elements.forEach(element => {
      element.style.animationDuration = `${s}s`;
    });
  }

  function init(module) {
    const sliderElements = getSliderElements(module);
    const ms =
      module != null ? module.getAttribute(`data-milliseconds`) : `5000`;

    initAnimationDuration(module, parseInt(ms, 10) / 1000);

    const interval = new Interval(1, sliderElements, ms);
    interval.start();

    sliderElements.controls.forEach(bar => {
      bar.addEventListener(`click`, event => {
        const activeIndex = parseInt(
          event.currentTarget.getAttribute(`data-index`),
          10
        );
        changeSlideToIndex(activeIndex, sliderElements, true);
        interval.stop();
      });
    });
  }

  slider.forEach(module => {
    resizeListener(module);

    const synKeyvisual = document.querySelector(`.in-head.syn-keyvisual`);

    if (synKeyvisual) {
      const synKeyheight =
        window.innerHeight -
        document.querySelector(`.topnavigation`).offsetHeight;
      synKeyvisual.style.height = `${synKeyheight}px`;
    }

    const arrowNav = document.querySelector(`.arrow-pagination`);
    if (
      arrowNav &&
      window.getComputedStyle(arrowNav, null).getPropertyValue(`display`) !==
        `none`
    ) {
      marginArrowElement(module);
    }

    if (module.querySelectorAll(`.slide`).length > 1) {
      init(module);
    } else {
      module.classList.add(`keyvisual`);
      const wrap = module.querySelector(`.thumbnail-wrap`);
      if (wrap) wrap.classList.add(`hidden`);
      const controls = module.querySelector(`.slider-controls`);
      if (controls) controls.classList.add(`hidden`);
    }
  });
}
