import getApiBase from "utils/apiBase";
import isRetina from "utils/isRetina";

export default function ImageSliderSyn(modules) {
  const thumbnailSizes = [
    [400, 800],
    [450, 900],
    [1100, 1100],
    [1920, 892],
    [3840, 1784]
  ];

  function init(module) {
    const slide = module.querySelector(`.slide`);
    const targetWidth = slide.offsetWidth;
    const targetHeight = slide.offsetHeight;
    let [width, height] = thumbnailSizes.find(
      ([thbWidth, thbHeight]) =>
        targetWidth <= thbWidth && targetHeight <= thbHeight
    );

    if (isRetina()) {
      width *= 2;
      height *= 2;
    }

    const { imageId } = slide.dataset;
    const backgroundUrl = `${getApiBase()}/thumbnail_direct/${imageId}?w=${width}&h=${height}`;

    slide.style.backgroundImage =
      slide.style.backgroundImage !== ``
        ? `url(${backgroundUrl}), ${slide.style.backgroundImage}`
        : `url(${backgroundUrl})`;
  }

  modules.forEach(init);
}
