export default () => {
  if (`NodeList` in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function f(callback, thisArg) {
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i += 1) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
  /**
   * Gibt true zurück wenn die (oder ALLE im Falle eines Arrays) Klasse hinzugfügt wurde, false wenn entfernt.
   * @param {string || string[]} className Der zu toggelnde Classenname
   */
  HTMLElement.prototype.toggle = function f(className) {
    const { classList } = this;
    const checkClass = name => {
      if (classList.contains(name)) {
        this.classList.remove(name);
        return false;
      }
      this.classList.add(name);
      return true;
    };
    if (Array.isArray(className)) {
      return className.reduce((accu, current) => {
        if (!checkClass(current)) accu = false;
        return accu;
      }, true);
    }
    if (typeof className === `string`) {
      return checkClass(className);
    }
    return undefined;
  };

  HTMLElement.prototype.siblings = function f() {
    const { parentElement } = this;
    const siblings = [];
    let nextSib = parentElement.firstElementChild;

    while (nextSib) {
      if (nextSib !== this) siblings.push(nextSib);
      nextSib = nextSib.nextElementSibling;
    }

    return siblings;
  };
  HTMLElement.prototype.siblingsAndSelf = function f() {
    const { parentElement } = this;
    const siblings = [];
    let nextSib = parentElement.firstElementChild;

    while (nextSib) {
      nextSib = nextSib.nextElementSibling;
    }

    return siblings;
  };

  HTMLElement.prototype.hide = function f() {
    this.classList.add(`hidden`);
  };
  HTMLElement.prototype.show = function f() {
    this.classList.remove(`hidden`);
  };
};
