import breakpoints from "utils/breakpoints";
import debounce from "utils/debounce";
import { ratelimit } from "utils/index";
import ß from "utils/customQuery";
import initMobile from "./topnavigation-mobile";
import { initDesktop, openSubmenu } from "./topnavigation-desktop";

const setSearchQuery = searchInput => () => {
  if (!window.searchPage) {
    // eslint-disable-next-line no-console
    console.warn(`Please define a search page in the Document Properties`);
    return;
  }

  if (window.searchPage === window.location.pathname) {
    window.search.setQuery(searchInput.value);
  } else {
    const url = `${
      window.searchPage.includes(`https`) ? `` : window.location.origin
    }${window.searchPage || window.location.pathname}?query=${
      searchInput.value
    }&tags=&company=;`;
    window.location.assign(url);
  }
};

const addScrollListener = () => {
  const topnav = document.querySelector(`.topnavigation`);
  window.addEventListener(`scroll`, () => {
    ratelimit(() => {
      // window.pageYOffset for IE11
      if (breakpoints.medium) return;
      if (window.scrollY >= 100 || window.pageYOffset >= 100) {
        if (!topnav.classList.contains(`shrink`)) {
          topnav.classList.add(`shrink`);
        }
      } else {
        topnav.classList.remove(`shrink`);
      }
    }, 200);
  });
};

function initSubPosition(deskNav, subMenu) {
  const secondlvl = subMenu.querySelectorAll(`div.second-level`);
  for (const submenu of secondlvl) {
    const firstLabel = deskNav.querySelector(
      `.first-label[data-level="${submenu.dataset.level}"]`
    );
    const posLeft = firstLabel.offsetLeft;
    submenu.style.margin = `${`0 0 0 ${posLeft - 15}`}px`;
  }
}

const reInitSubPosition = (deskNav, subMenu) => () => {
  initSubPosition(deskNav, subMenu);
};

function resizeHandler(deskNav, subMenu) {
  window.addEventListener(
    `resize`,
    debounce(reInitSubPosition(deskNav, subMenu), this, 100)
  );
}

export default function() {
  let initalizedDesktop = false;
  let initalizedMobile = false;
  window.useMobileNav = false;
  function init() {
    const topnav = ß(`.topnavigation`);

    addScrollListener();
    /*     if (breakpoints.large() && !window.useMobileNav) {
      // If we dont have the mobile nav already...
      topnav.addEventListener(
        `touchstart`,
        () => {
          const modal = new ModalText({
            title: `Möchten Sie die Touchnavigation nutzen?`,
            text: `Wir haben erkannt, dass Sie mit einem Touch-fähigem Device arbeiten. Möchten Sie daher die Touch-optimierte Navigation verwenden?`,
            accept: {
              text: `JA`,
              function: () => {
                modal.destroy();
                window.useMobileNav = true;
                init();
              }
            },
            abort: {
              text: `NEIN`,
              function: () => {
                modal.destroy();
              }
            }
          });
          modal.show();
        },
        { once: true }
      );
    } else {
      window.useMobileNav = true;
    } */
    /*     if (window.useMobileNav) {
      topnav.classList.add(`mobile-override`);
      document.body.classList.add(`has-mobile-nav`);
    } else {
      topnav.classList.remove(`mobile-override`);
      document.body.classList.remove(`has-mobile-nav`);
    } */
    if (breakpoints.large() && !initalizedDesktop) {
      initalizedDesktop = true;
      initDesktop(initSubPosition, resizeHandler, setSearchQuery, openSubmenu);
    } else if (!initalizedMobile) {
      initalizedMobile = true;
      topnav.querySelector(`.mobile-nav`).classList.add(`desktop`);
      initMobile(setSearchQuery, breakpoints.large());
    }
  }

  init();
  window.addEventListener(`resize`, debounce(init, this, 100));
}
