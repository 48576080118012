import { h } from "preact";
import { thumbnailSizes } from "component/../modules/medium-tile-grid-config"; // Big oof
import { useEffect, useRef, useState } from "preact/hooks";
import getApiBase from "utils/apiBase";
import { useInView } from "utils/useIntersection";

function getThumbnailSize(ref: HTMLElement) {
  const targetHeight = ref.offsetHeight;
  const targetWidth = ref.offsetWidth;
  const dims = thumbnailSizes.find(
    ([width, height]) => targetHeight <= height && targetWidth <= width
  );
  return dims;
}

export function MediumTGItem({
  attribution,
  blog,
  publishedDate,
  eventDate,
  href,
  imageId,
  label,
  teaser,
  tileSize,
  title,
  target,
  intersectionRoot,
  learnMoreLinkText
}: {
  attribution?: string;
  blog: {
    author: {
      name: string;
      image: number;
    };
  };
  publishedDate: string;
  eventDate: string;
  href: string;
  imageId: number;
  label: string;
  teaser: string;
  tileSize: string;
  title: string;
  target: string;
  intersectionRoot?: HTMLElement;
  learnMoreLinkText?: string;
}) {
  const self = useRef(null);
  const imageSection = useRef(null);
  const [woke, setWoke] = useState(false);
  const [dimensions, setDimensions] = useState(null);

  const inView = useInView(self, {
    root: intersectionRoot,
    rootMargin: `1000px`
  });

  useEffect(() => {
    if (inView === true && !woke) {
      setWoke(true);
    }
  }, [inView, woke]);

  useEffect(() => {
    setDimensions(getThumbnailSize(imageSection.current));
  }, [imageSection]);

  const resizeObserver = useRef(
    new window.ResizeObserver(entries => {
      setDimensions(getThumbnailSize(entries[0].target));
    })
  );

  useEffect(() => {
    resizeObserver.current.observe(imageSection.current);

    return () => {
      resizeObserver.current.unobserve(imageSection.current);
    };
  });

  const authorImage = blog?.author?.image;
  const authorName = blog?.author?.name;

  const publishDate = publishedDate != null ? new Date(publishedDate) : null;
  const eventDateObject = eventDate ? new Date(eventDate) : null;
  return (
    <a
      ref={self}
      href={href}
      rel={
        href.startsWith(`/`) ||
        new URL(href).hostname === window.location.hostname
          ? undefined
          : `noopener`
      }
      target={target}
      class={`grid-tile ${tileSize ?? `s1-3`}`}
    >
      <div class="overlays">
        <div class="head-section">
          {eventDateObject && (
            <div class="date-flag">
              <div class="month">
                {eventDateObject.toLocaleString(`de-DE`, { month: `short` })}
              </div>
              <div class="day">
                {eventDateObject.toLocaleString(`de-DE`, { day: `numeric` })}
              </div>
            </div>
          )}
          {attribution && <span class="company-marker">{attribution}</span>}
        </div>
        <div class="meta-section">
          {authorImage != null && authorImage !== -1 && woke && (
            <img
              src={`${getApiBase()}/thumbnail_direct/${
                blog.author.image
              }?w=64&h=64&solid`}
              alt={blog.author.name}
              class="author-image"
            />
          )}
          {authorName != null && (
            <small class="author-name">{blog.author.name}</small>
          )}
          {label && <span class="content-type">{label}</span>}
          {publishDate != null && (
            <small class="publish-date">
              {new Intl.DateTimeFormat(`de-DE`).format(publishDate)}
            </small>
          )}
        </div>
      </div>
      <div class="base">
        <div
          ref={imageSection}
          class="image-section"
          style={
            woke && dimensions != null
              ? `background-image: url('${getApiBase()}/thumbnail_direct/${imageId}?w=${
                  dimensions[0]
                }&h=${dimensions[1]}&solid')`
              : `background-image: none`
          }
        />
        <div class="text-section">
          <div class="main-text-section">
            <div class="alt-meta-section">
              {label && <span class="content-type">{label}</span>}
              {publishDate != null && (
                <small class="publish-date">
                  {new Intl.DateTimeFormat(`de-DE`).format(publishDate)}
                </small>
              )}
            </div>
            <h3 class="headline">{title}</h3>
            <p class="teaser" dangerouslySetInnerHTML={{ __html: teaser }} />
          </div>

          {learnMoreLinkText && href && (
            <div class="learn-more-link-section">
              <span class="learn-more-link">{learnMoreLinkText}</span>
            </div>
          )}
        </div>
      </div>
    </a>
  );
}
