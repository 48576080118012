import { h, render } from "preact";
import MediumTGGrid from "component/search/results/MediumTGGrid";
import MediumTGSlider from "component/search/results/MediumTGSlider";

export default function newMediumTileGrid(modules) {
  function init(module) {
    const key = module.dataset.mtgkey;
    const config = window[key];

    if (config.items.length > 0) {
      if (config.slider) {
        render(<MediumTGSlider items={config.items} />, module);
      } else {
        render(
          <MediumTGGrid
            items={config.items}
            dontStretchTiles={config.dontStretchTilesToFillRow}
          />,
          module
        );
      }
    }
  }

  modules.forEach(init);
}
