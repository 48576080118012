/* 1/3 Text,s1-3
2/3 Text,s2-3
3/3 Text,s3-3
1/3 Image,i1-3
2/3 Image,i2-3
3/3 Image,i3-3 */

/* This Helper works in 1/3 to avoid working with decimals so we dont have to accont for epsilon */

const getGridSize = (className: string) =>
  className.charCodeAt(1) - `0`.charCodeAt(0);

export const getTotalThirds = (arr: any): number =>
  arr.reduce((acc, { tileSize }) => acc + getGridSize(tileSize), 0);
