/**
 * @param {string} selector CSS Selector
 * @param {HTMLElement} root Root Element, wenn nichts angegeben "document"
 */
const customQuery = (selector, root = document) => {
  let out = [];
  if (selector[0] === `>`) {
    selector = `:scope${selector}`;
  }
  if (Array.isArray(selector)) {
    // TODO: Selector als array
    return undefined;
  }
  out = Array.from(root.querySelectorAll(selector));
  if (out.length === 0) return null;
  return out.length === 1 ? out[0] : out;
};
export default customQuery;
