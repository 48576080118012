export default function(root = document) {
  const instances = root.querySelectorAll(
    `.style-section[data-background-image]`
  );
  return {
    instances,
    shouldInit: instances.length > 0,
    initName: `style-section`
  };
}
