export default () => {
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function f(handler) {
      const startTime = Date.now();

      return setTimeout(() => {
        handler({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50.0 - (Date.now() - startTime));
          }
        });
      }, 1);
    };
};
