export const set = (key, value, expires) => {
  const expiresValue =
    expires === false
      ? new Date(`01.19.2038`).toUTCString() // 32 Bit rollover
      : new Date(new Date() * 1 + expires * 864e5).toUTCString();

  const encodedKey = encodeURIComponent(key);
  const encodedValue = window.btoa(JSON.stringify(value));

  document.cookie = `${encodedKey}=${encodedValue}; expires=${expiresValue}; path=/`;
};
export const get = getKey => {
  const { cookie } = document;
  if (cookie === ``) return undefined;
  const cookieArray = cookie.split(`; `);
  for (const keyValueString of cookieArray) {
    const [key, value] = keyValueString.split(`=`);
    const decodedKey = decodeURIComponent(key);
    if (decodedKey === getKey) {
      const decodedValue = window.atob(value);
      const json = JSON.parse(decodedValue);
      return json;
    }
  }
  return undefined;
};
export const remove = key => {
  const encodedKey = encodeURIComponent(key);
  document.cookie = `${encodedKey}=; path=/; expires=${new Date(
    0
  ).toUTCString()}`;
};
