import ß from "utils/customQuery";
import allImagesLoaded from "utils/allImagesLoaded";
import hover from "utils/hover";
import spreadSafe from "utils/spreadsafe";
import { fontSizeByContainerWidth } from "utils/dynamicFontSize";

function removeCssClasses(navigation: any) {
  navigation.querySelectorAll(`.layer-one-active`).forEach(navItem => {
    navItem.classList.remove(`layer-one-active`);
  });
  navigation.querySelectorAll(`.open`).forEach(navItem => {
    navItem.classList.remove(`open`);
  });
}

export function openSubmenu(nextLevel, subMenu, detailSlides, thisLevelLabels) {
  const index = this.dataset.level;
  const centeredSubmenu = document
    .querySelector(`.desktop-nav`)
    .classList.contains(`centered-submenu`);

  if (thisLevelLabels != null) {
    for (const label of spreadSafe(thisLevelLabels)) {
      if (label === this) {
        label.classList.add(`is-active`);
      } else {
        label.classList.remove(`is-active`);
      }
    }
  }
  for (const menu of spreadSafe(nextLevel)) {
    if (menu.dataset.level === index) {
      menu.classList.add(`is-active`);

      if (menu.classList.contains(`second-level`) && centeredSubmenu) {
        const ulWidth = menu.querySelector(`ul.second-level`).offsetWidth;
        // ul.second-level width + horizontal padding of div.second-level divided by 4
        menu.style.transform = `translateX(-${(ulWidth + 32) / 4}px)`;
      }
    } else {
      menu.classList.remove(`is-active`);

      if (menu.classList.contains(`second-level`) && centeredSubmenu) {
        menu.style.transform = `translateX(0px)`;
      }
    }
  }
  if (detailSlides != null) {
    let parentIndex = index.slice(0, -1);
    let targetSlide;

    while (targetSlide === undefined) {
      for (const slide of spreadSafe(detailSlides)) {
        if (slide.dataset.level === index) {
          targetSlide = slide;
        } else if (slide.dataset.level === parentIndex) {
          targetSlide = slide;
        }
        slide.classList.remove(`is-active`);
      }

      if (parentIndex.length <= 1) {
        // at the topmost element, no need to continue searching;
        break;
      } else {
        parentIndex = parentIndex.slice(0, -1);
      }
    }

    if (targetSlide) {
      targetSlide.classList.add(`is-active`);
    }
  }
  if (subMenu != null) {
    window.requestAnimationFrame(() => {
      subMenu.classList.add(`is-active`);
    });
  }
}

function highlightCurrentUrl(menu, secondLevel, thirdLevel) {
  // check if url is in submenu; highlight topnav element
  const currentUrl =
    window.location.pathname !== `/` && window.location.pathname !== `/en`
      ? window.location.pathname
      : ``;
  const topnavNodes = menu.getElementsByTagName(`li`);

  if (typeof topnavNodes !== `undefined`) {
    // check all topnav nodes
    for (let i = 0; i < topnavNodes.length; i += 1) {
      if (typeof topnavNodes[i].dataset !== `undefined`) {
        const topNavLevel = topnavNodes[i].dataset.level;
        const topNavElementParent =
          topnavNodes[i].parentNode.nodeName === `A`
            ? topnavNodes[i].parentNode
            : topnavNodes[i];

        // check links in first line
        if (topnavNodes[i].parentNode.nodeName === `A`) {
          if (
            currentUrl !== `` &&
            currentUrl === topnavNodes[i].parentNode.getAttribute(`href`)
          ) {
            topNavElementParent.classList.add(`highlight`);
            break;
          }
        }

        // check all links in subnav
        if (
          topNavLevel != null &&
          topNavLevel !== `undefined` &&
          topNavLevel !== `` &&
          secondLevel != null &&
          secondLevel !== `undefined` &&
          secondLevel !== ``
        ) {
          // secondLevel check
          for (let j = 0; j < secondLevel.length; j += 1) {
            if (
              typeof secondLevel[j] !== `undefined` &&
              currentUrl !== `` &&
              topNavLevel === secondLevel[j].dataset.level
            ) {
              if (
                secondLevel[j].innerHTML
                  .toLowerCase()
                  .includes(`"${currentUrl}"`)
              ) {
                console.log(secondLevel[j].innerHTML.toLowerCase(), currentUrl);
                topNavElementParent.classList.add(`highlight`);
                break;
              }
            }
          } // for secondLevel

          // thirdLevel check
          if (
            thirdLevel != null &&
            thirdLevel !== `undefined` &&
            thirdLevel !== `` &&
            secondLevel != null &&
            secondLevel !== `undefined` &&
            secondLevel !== ``
          ) {
            for (let k = 0; k < thirdLevel.length; k += 1) {
              if (
                typeof secondLevel[k] !== `undefined` &&
                thirdLevel[k] !== `undefined` &&
                currentUrl !== `` &&
                topNavLevel === thirdLevel[k].dataset.level
              ) {
                if (
                  thirdLevel[k].innerHTML.toLowerCase().includes(currentUrl)
                ) {
                  topNavElementParent.classList.add(`highlight`);
                  break;
                }
              }
            } // for thirdLevel
          }
        } // if subnav
      } // if topnavNodes dataset
    } // for firstLevel
  } // if topnavNodes
}

export function initDesktop(
  initSubPositionFunction,
  resizeHandlerFunction,
  setSearchQueryFunction,
  openSubmenuFunction
) {
  const deskNav = ß(`.topnavigation .desktop-nav`);
  const searchWrap = ß(`.search-wrap`, deskNav);
  const search = searchWrap != null;
  const searchIcon =
    search &&
    (ß(`.search-icon`, searchWrap) ?? ß(`.search-icon-meta`, searchWrap));
  const metaSearchIcon = search && ß(`.meta-nav .search-icon`);
  const searchClose =
    search && (ß(`.close-icon`, deskNav) ?? ß(`.meta-nav .close-search-icon`));
  const searchForm = search && ß(`form.search-form`, searchWrap);
  const searchInput = search && ß(`input[name="search"]`, searchForm);
  const menu = ß(`.menu`, deskNav);
  const menuItems = ß(`.menu > *`);
  const firstLabel = ß(`.first-label`, menu);
  const subMenuWrap = ß(`.sub-menu-wrap`, deskNav);
  const subMenu = ß(`.sub-menu`, subMenuWrap);
  const secondLevel = ß(`div.second-level`, subMenuWrap);
  const secondLabel = ß(`.second-label`, subMenuWrap);
  const detailSlides = ß(`.second-level-detail .details`, subMenuWrap);
  const thirdLabel = ß(`.third-label`, subMenuWrap);
  const thirdLevel = ß(`div.third-level`, subMenuWrap);
  const forthLevel = ß(`div.forth-level`, subMenuWrap);
  const manualClose = ß(`.manual-close-button`, subMenuWrap);

  let navWidth = 0;
  if (menu.classList.contains(`center-nav`)) {
    for (const menuLink of ß(`.menu > a`)) {
      navWidth += menuLink.offsetWidth;
    }
  }

  let removeClass = false;
  if (deskNav.classList.contains(`small`)) {
    const navImage = deskNav.querySelector(`img.navigation-logo`);
    allImagesLoaded([navImage], () => {
      initSubPositionFunction(deskNav, subMenuWrap);
      resizeHandlerFunction(deskNav, subMenuWrap);
    });
  }
  if (search) {
    if (metaSearchIcon && searchIcon) {
      searchIcon.addEventListener(`click`, () => {
        setSearchQueryFunction(searchInput)();
      });
    }
    const icon = metaSearchIcon ?? searchIcon;
    icon.addEventListener(`click`, () => {
      if (menu.classList.contains(`collapsed-horizontal`)) {
        setSearchQueryFunction(searchInput)();
      } else {
        menu.classList.add(`collapsed-horizontal`);
        if (navWidth !== 0) searchWrap.style.flexBasis = `${navWidth}px`;
        if (search) {
          // eslint-disable-next-line no-unused-vars, no-undef
          if (!window.search) {
            // Nenen so machen wir das nicht
            searchInput.value = ``;
          } else {
            searchInput.value =
              window.search.query !== `*` ? window.search.query : ``;
          }
          searchWrap.classList.add(`is-active`);
          searchClose.classList.remove(`hidden`);
          searchInput.focus();
          if (metaSearchIcon) metaSearchIcon.classList.add(`hidden`);
        }
      }
    });
    searchClose.addEventListener(`click`, () => {
      menu.classList.remove(`collapsed-horizontal`);
      if (navWidth !== 0) searchWrap.style.flexBasis = 0;
      if (search) {
        searchWrap.classList.remove(`is-active`);
        searchClose.classList.add(`hidden`);
        if (metaSearchIcon) metaSearchIcon.classList.remove(`hidden`);
      }
    });
    searchForm.onsubmit = setSearchQueryFunction(searchInput);
  }
  hover(
    spreadSafe(firstLabel),
    function f() {
      for (const label of firstLabel) {
        if (label === this) {
          label.classList.add(`open`);
        }
      }
    },
    function f(event) {
      if (this.dataset.level == null || this.dataset.level === ``) {
        this.classList.remove(`open`);
        return;
      }
      const { pageX } = event;
      const { left, right } = this.getBoundingClientRect();
      if (pageX >= Math.floor(right) || pageX <= Math.floor(left)) {
        this.classList.remove(`open`);
      }
    }
  );
  hover(
    [
      ...spreadSafe(firstLabel),
      ...spreadSafe(secondLabel),
      ...spreadSafe(thirdLabel)
    ],
    function f() {
      switch (this.dataset.level.length) {
        case 1:
          openSubmenuFunction.call(
            this,
            secondLevel,
            subMenuWrap,
            detailSlides
          );
          openSubmenuFunction.call(
            this,
            thirdLevel,
            null,
            detailSlides,
            secondLabel
          ); // Clears the second Lvl
          openSubmenuFunction.call(
            this,
            forthLevel,
            null,
            detailSlides,
            thirdLabel
          ); // Clears the third Lvl
          break;
        case 2:
          openSubmenuFunction.call(
            this,
            thirdLevel,
            null,
            detailSlides,
            secondLabel
          );
          openSubmenuFunction.call(
            this,
            forthLevel,
            null,
            detailSlides,
            thirdLabel
          );
          break;
        case 3:
          openSubmenuFunction.call(
            this,
            forthLevel,
            null,
            detailSlides,
            thirdLabel
          );
          break;
        default:
          openSubmenuFunction.call(this, secondLevel, null, null, detailSlides); // Clears the first Lvl
          openSubmenuFunction.call(this, thirdLevel, null, null, secondLabel); // Clears the second Lvl
          openSubmenuFunction.call(this, forthLevel, null, null, thirdLabel); // Clears the third Lvl
          break;
      }
    },
    (a, b, c, d) => {
      switch (d.target.dataset.level.length) {
        case 1:
          subMenuWrap.classList.remove(`is-active`);
          break;
        default:
          break;
      }
    },
    secondLevel,
    subMenuWrap,
    detailSlides
  );

  // Prevent drawer from closing, if user hovers over it:
  if (manualClose != null) {
    manualClose.addEventListener(`click`, () => {
      spreadSafe(firstLabel).forEach(label => {
        label.classList.remove(`open`);
      });
      subMenuWrap.classList.remove(`is-active`);
    });

    hover(
      subMenuWrap,
      sub => {
        sub.classList.add(`is-active`);
      },
      sub => {
        spreadSafe(firstLabel).forEach(label => {
          label.classList.remove(`open`);
        });
        sub.classList.remove(`is-active`);
      },
      subMenuWrap
    );
  } else {
    hover(
      subMenu,
      sub => {
        sub.classList.add(`is-active`);
      },
      sub => {
        spreadSafe(firstLabel).forEach(label => {
          label.classList.remove(`open`);
        });
        sub.classList.remove(`is-active`);
      },
      subMenuWrap
    );
  }
  if (menuItems != null) {
    let entries = menuItems;
    if (typeof menuItems.forEach !== `function`) {
      entries = [menuItems];
    }

    entries.forEach(menuItem => {
      let hasSubNav = false;
      if (menuItem.tagName === `A`) {
        if (parseInt(menuItem.querySelector(`li`).dataset.level, 10) >= 0) {
          hasSubNav = true;
        } else {
          hover(
            menuItem,
            () => {
              deskNav.querySelectorAll(`.layer-one-active`).forEach(navItem => {
                navItem.classList.remove(`layer-one-active`);
              });
              deskNav.querySelectorAll(`.open`).forEach(navItem => {
                navItem.classList.remove(`open`);
              });
            },
            false,
            menuItem
          );
        }
      } else if (menuItem.tagName === `LI`) {
        if (parseInt(menuItem.dataset.level, 10) >= 0) {
          hasSubNav = true;
        } else {
          hover(
            menuItem,
            () => {
              deskNav.querySelectorAll(`.layer-one-active`).forEach(navItem => {
                navItem.classList.remove(`layer-one-active`);
              });
              deskNav.querySelectorAll(`.open`).forEach(navItem => {
                navItem.classList.remove(`open`);
              });
            },
            false,
            menuItem
          );
        }
      }

      if (hasSubNav) {
        hover(
          menuItem,
          item => {
            removeClass = false;
            deskNav.querySelectorAll(`.layer-one-active`).forEach(navItem => {
              navItem.classList.remove(`layer-one-active`);
            });
            deskNav.querySelectorAll(`.open`).forEach(navItem => {
              navItem.classList.remove(`open`);
            });
            item.classList.add(`layer-one-active`);
          },
          false,
          menuItem
        );
      }
    });
  }

  /* global MutationObserver */
  const mutationObserver = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.oldValue === `sub-menu-wrap is-active` && removeClass) {
        removeCssClasses(deskNav);
      } else if (mutation.oldValue === `sub-menu-wrap is-active`) {
        removeClass = true;
      }
    });
  });

  // Starts listening for changes in the '.search-trigger' HTML element
  mutationObserver.observe(document.querySelector(`.sub-menu-wrap`), {
    attributes: true,
    characterData: false,
    childList: false,
    subtree: false,
    attributeOldValue: true,
    characterDataOldValue: false
  });

  /* The above logic assumes that the mutation happens twice. When leaving the navigation through
     the logo on the left side, it's only triggered once. The css classes are not removed and the hover state
     remains. The following code removes the classes whenever the visitor moves the cursor over the logo.
  */
  const navigationImage = document.querySelector(`div.navigation-image`);
  if (navigationImage != null) {
    navigationImage.addEventListener(`mouseover`, () => {
      removeCssClasses(deskNav);
    });
  }

  // checks the current url and highlight the topnav node
  if (menu !== `undefined`) {
    highlightCurrentUrl(menu, secondLevel, thirdLevel);
  }
}
