import { useState, useEffect, useRef } from "preact/hooks";

export const useInView = (selfRef, options) => {
  const [inView, setInView] = useState(false);
  const observer = useRef(null);
  useEffect(() => {
    const newObserver = new window.IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            observerInstance.disconnect();
            setInView(true);
          }
        });
      },
      options
    );
    observer.current = newObserver;
    if (selfRef != null && observer != null) {
      observer.current.observe(selfRef.current);
    }
  }, [options]);

  return inView;
};
