import ß from "utils/customQuery";

export default function initMobile(setSearchQuery, breakpointLarge: boolean) {
  const mobileNav = ß(`.topnavigation .mobile-nav`);
  const menuBlock = ß(`.menu-block`, mobileNav);
  const closeIcon = ß(`.close-icon`, menuBlock);
  const openIcon = ß(`.open-icon`, menuBlock);
  const searchWrap = ß(`.search-container`, mobileNav);
  const search = searchWrap != null;
  const _searchIcon =
    search &&
    (ß(`.search-icon`, menuBlock) ?? ß(`.search-icon-meta`, menuBlock));
  const _metaSearchIcon = search && ß(`.meta-nav .search-icon`);
  const searchIcon =
    _metaSearchIcon && breakpointLarge ? _metaSearchIcon : _searchIcon;
  const _closeIcon =
    search &&
    (ß(`.close-search-icon`, menuBlock) ??
      ß(`.close-search-icon-meta`, menuBlock));
  const _metaSearchCloseIcon = ß(`.meta-nav .close-search-icon`);
  const searchCloseIcon =
    _metaSearchCloseIcon && breakpointLarge ? _metaSearchCloseIcon : _closeIcon;

  const searchForm = search && ß(`form.search-form`, searchWrap);
  const searchInput = search && ß(`input[name="search"]`, searchForm);
  const first = ß(`.first-level`, mobileNav);
  const expandIcons = ß(`span.label`, mobileNav);
  const goHome = ß(`.go-back`, mobileNav);
  const treeRoot = ß(`ul.first`, mobileNav);
  const aboutBlock = ß(`.about-block-container`, mobileNav);
  const everyLi = ß(`ul .label`, mobileNav);
  const languageSwitcher = ß(`.ls-container`, mobileNav);

  const checkForSubItems = currentLevel => {
    // If the current nav-level has sub-items, the item should open the next navigation layer, and not the content page
    const nextLevel = currentLevel.parentElement.querySelector(`.level`);

    return !!nextLevel;
  };

  const removeLinkHidden = currentLevel => {
    if (currentLevel.querySelector(`.hidden`)) {
      currentLevel.querySelector(`.hidden`).classList.remove(`hidden`);
      currentLevel.querySelector(`.link-hoverable`).classList.add(`hidden`);
    }
  };

  const setActiveSubNav = () => {
    const currentPageIdElement = document.querySelector(`meta[name=page-id]`);
    const currentPageId = currentPageIdElement.getAttribute(`content`);
    if (currentPageId === null || currentPageId === ``) {
      return;
    }
    let targetElement = mobileNav.querySelector(
      `a[data-id="${currentPageId}"]`
    );
    if (targetElement === null) {
      return;
    }
    const directListParent = targetElement.closest(`li`);
    if (directListParent) directListParent.classList.add(`current-position`);
    let reachedFirstLevel = false;
    let traversedLevels = 0;
    do {
      const currentLevel = targetElement.closest(`div.level`);
      if (currentLevel) {
        currentLevel.classList.add(`is-active`);
        const currentLevelList = currentLevel.closest(`ul`);
        if (currentLevelList) {
          Array.from(currentLevelList.children).forEach(child => {
            child.classList.add(`collapsed`);
          });
          const thisListEntry = currentLevel.closest(`li`);
          thisListEntry.classList.remove(`collapsed`);
          thisListEntry.classList.add(`is-active`);
          if (traversedLevels === 0) thisListEntry.classList.add(`overview`);
          traversedLevels += 1;
          targetElement = thisListEntry;
        }
      } else {
        reachedFirstLevel = true;
      }
    } while (!reachedFirstLevel);
    if (traversedLevels >= 1) {
      mobileNav.querySelector(`li.go-back`).classList.remove(`collapsed`);
      languageSwitcher && languageSwitcher.classList.add(`collapsed`);
    }
  };

  const clearLevel = level => {
    for (const className of [`is-active`, `overview`, `collapsed`]) {
      const query = ß(`.${className}`, level);
      if (query !== null) {
        if (Array.isArray(query)) {
          for (const subSib of query) {
            subSib.classList.remove(className);
          }
        } else {
          query.classList.remove(className);
        }
      }
    }
  };
  const closeFirstLevel = event => {
    const { currentTarget } = event;
    const parentElement = currentTarget.closest(`li`);
    const siblings = parentElement.siblings();
    for (const sib of siblings) {
      sib.classList.remove(`collapsed`);
      sib.classList.remove(`overview`);
      if (sib.classList.contains(`is-active`)) {
        clearLevel(sib);
      }
      sib.classList.remove(`is-active`);
    }
    currentTarget.classList.add(`collapsed`);
    languageSwitcher && languageSwitcher.classList.remove(`collapsed`);
  };
  const findLastVisible = (element = treeRoot) => {
    const nextEle = element.querySelector(`li.is-active`);
    if (nextEle != null) {
      return findLastVisible(nextEle.querySelector(`li.is-active ul`));
    }
    const lis = element.children;
    return lis[lis.length - 1];
  };
  const moveAboutBelowItem = element => {
    if (aboutBlock != null) {
      aboutBlock.style.position = `absolute`;
      aboutBlock.style.top = `${element.offsetTop + 45}px`;
    }
  };
  const openNextLevel = currentTarget => {
    const listElement =
      currentTarget.nodeName === `LI`
        ? currentTarget
        : currentTarget.closest(`li`);
    const query = ß(`div.level`, listElement);
    if (query === null) return;
    const nextLevel = query.length ? query[0] : query;
    if (nextLevel === undefined) return;
    // Find Parent of Parent
    const grandParent = listElement.parentElement.closest(`li`);
    if (grandParent !== null) {
      grandParent.classList.remove(`overview`);
    }
    listElement.siblings().forEach(sib => {
      sib.classList.add(`collapsed`);
      sib.classList.remove(`is-active`);
    });
    languageSwitcher && languageSwitcher.classList.add(`collapsed`);
    goHome.classList.remove(`collapsed`);
    nextLevel.classList.add(`is-active`);
    listElement.classList.add(`is-active`);
    listElement.classList.add(`overview`);
  };
  const closeNextLevel = currentTarget => {
    const listElement = currentTarget.closest(`li`);
    clearLevel(listElement);
    openNextLevel(listElement);
  };

  const toggleNextLevel = event => {
    const { currentTarget } = event;

    if (
      (event.target.nodeName === `A` && event.target.href === ``) ||
      checkForSubItems(currentTarget)
    ) {
      removeLinkHidden(currentTarget);
      openNextLevel(currentTarget);
    }
    // if (currentTarget !== event.target && event.target.nodeName !== `I`) return;
    const parentElement = currentTarget.closest(`li`);
    if (parentElement.classList.contains(`is-active`)) {
      if (parentElement.classList.contains(`overview`)) {
        closeNextLevel(currentTarget);
      }
    } else {
      openNextLevel(currentTarget);
    }

    window.requestAnimationFrame(() => {
      moveAboutBelowItem(findLastVisible());
    });
  };

  const setEmptyFolderLinks = () => {
    everyLi.forEach(li => {
      const currentLink = ß(`a`, li);
      if (currentLink.getAttribute(`href`) == null) {
        li.addEventListener(`click`, () => toggleNextLevel);
      }
    });
  };
  const openNavigation = () => {
    first.classList.remove(`hidden`);
    closeIcon.classList.remove(`hidden`);
    openIcon.classList.add(`hidden`);

    document.querySelector(`body`).classList.add(`disable-scroll`);

    setEmptyFolderLinks();
    checkForSubItems(first);
    setActiveSubNav();
  };
  const closeNavigation = () => {
    first.classList.add(`hidden`);
    closeIcon.classList.add(`hidden`);
    openIcon.classList.remove(`hidden`);
    document.querySelector(`body`).classList.remove(`disable-scroll`);
  };

  openIcon.addEventListener(`click`, openNavigation);
  closeIcon.addEventListener(`click`, closeNavigation);
  if (search) {
    searchIcon.addEventListener(`click`, () => {
      const searchbox = document.querySelector(`.searchbox-input`);
      if (searchbox) {
        searchbox.focus();
        return;
      }
      closeNavigation();
      document.querySelector(`body > article`).classList.add(`search-active`);
      // eslint-disable-next-line no-unused-vars, no-undef
      if (!window.search) {
        searchInput.value = ``;
      } else {
        searchInput.value =
          window.search.query !== `*` ? window.search.query : ``;
      }
      menuBlock.classList.add(`search-active`);
      searchWrap.classList.add(`is-active`);
      searchIcon.classList.add(`hidden`);
      openIcon.classList.add(`hidden`);
      searchCloseIcon.classList.remove(`hidden`);
      searchInput.focus();
    });
    searchCloseIcon.addEventListener(`click`, () => {
      document
        .querySelector(`body > article`)
        .classList.remove(`search-active`);
      menuBlock.classList.remove(`search-active`);
      searchWrap.classList.remove(`is-active`);
      openIcon.classList.remove(`hidden`);
      searchIcon.classList.remove(`hidden`);
      searchCloseIcon.classList.add(`hidden`);
    });
    searchForm.onsubmit = setSearchQuery(searchInput);
  }
  if (Array.isArray(expandIcons)) {
    expandIcons.forEach(button => {
      button.addEventListener(`click`, toggleNextLevel);
    });
  } else if (expandIcons != null) {
    expandIcons.addEventListener(`click`, toggleNextLevel);
  }
  if (goHome != null) {
    goHome.addEventListener(`click`, closeFirstLevel);
  }
}
