// Utils
import initCustomMethods from "utils/initCustomMethods";
import { thumbnailChangerInit as initThumbnailChanger } from "utils/thumbnailHelpers";
import initRequestIdleCallback from "utils/initRequestIdleCallback";
import conditionalImport from "utils/conditionalImport";

// Queries
import qTextVideo from "query/q_textvideo";
import qTwoColumnCards from "query/q_twocolumncards";
import { initModules } from "utils/initModules";

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  require(`preact/debug`);
} else {
  // eslint-disable-next-line global-require
  require(`preact/devtools`);
}

// Polyfills, utils, window, methods etc
initCustomMethods();
initThumbnailChanger();
initRequestIdleCallback();

if (window.function) {
  if (window.function.media) {
    window.function.media.push(() => {
      conditionalImport(qTextVideo);
      conditionalImport(qTwoColumnCards);
    });
  }
}
window.jsIsInitilized = new Promise(resolve => {
  window.jsIsInitilizedTrigger = resolve;
});

initModules().then(() => window.jsIsInitilizedTrigger());
