export default function(modules) {
  let slideChangeCallback = () => {};

  function removeActiveFlag(elements) {
    elements.forEach(element => {
      if (element.classList.contains(`active`)) {
        element.classList.remove(`active`);
      }
    });
  }

  function changeActiveElement(n, elements, permanent = false) {
    removeActiveFlag(elements);
    elements[n].classList.add(`active`);
    if (permanent) {
      elements[n].classList.add(`permanent`);
    }
  }

  function changeActiveControlElement(
    n,
    controlElementsDesktop,
    controlElementsMobile,
    controlElementsDesktopSmall,
    permanent = false
  ) {
    changeActiveElement(n, controlElementsDesktop, permanent);
    changeActiveElement(n, controlElementsMobile, permanent);
    changeActiveElement(n, controlElementsDesktopSmall, permanent);
  }

  function changeSlideToIndex(
    n,
    sliderElements,
    controlBar,
    permanent = false
  ) {
    changeActiveElement(n, sliderElements.slides);
    changeActiveElement(n, sliderElements.highlightImages);

    changeActiveControlElement(
      n,
      sliderElements.controlElementsDesktop,
      sliderElements.controlElementsMobile,
      sliderElements.controlElementsDesktopSmall,
      permanent
    );

    slideChangeCallback(n);
  }

  function changeSlide(event, sliderElements, controlBar, permanent = false) {
    const n = parseInt(event.currentTarget.getAttribute(`data-index`), 10);
    changeSlideToIndex(n, sliderElements, controlBar, permanent);
  }

  function changeSlideInDirection(
    direction,
    sliderElements,
    controlBar,
    permanent = false
  ) {
    // direction values: 1 = right, -1 = left

    const activeElement = controlBar.querySelector(`.active`);
    let activeIndex =
      parseInt(activeElement.getAttribute(`data-index`), 10) + direction;

    if (activeIndex === -1) {
      activeIndex = sliderElements.slides.length - 1;
    } else if (activeIndex > sliderElements.slides.length - 1) {
      activeIndex = 0;
    }

    changeSlideToIndex(activeIndex, sliderElements, controlBar, permanent);
  }

  function initDesktopNav(sliderElements) {
    const {
      controlBarDesktop,
      controlBarDesktopSmall,
      controlElementsDesktop
    } = sliderElements;

    const forceIndicator =
      controlBarDesktop.getAttribute(`data-forceIndicator`) === `1`;

    let tabsOverflowing =
      controlBarDesktop.offsetWidth >
      controlBarDesktop.parentElement.offsetWidth;

    if (!tabsOverflowing) {
      for (let i = 0; i < controlElementsDesktop.length; i += 1) {
        tabsOverflowing =
          controlElementsDesktop[i].querySelector(`.element`).offsetHeight <=
          controlElementsDesktop[i].querySelector(`.text`).scrollHeight;

        if (tabsOverflowing) {
          break;
        }
      }
    }

    if (controlBarDesktop.childElementCount > 1) {
      if (tabsOverflowing || forceIndicator) {
        controlBarDesktopSmall.parentElement.classList.remove(`hidden`);
        controlBarDesktop.classList.add(`hidden`);
      } else {
        controlBarDesktop.parentElement.classList.remove(`invisible`);
      }
    }
  }

  const initArrowNav = (module, sliderElements, interval) => {
    if (sliderElements.controlBarMobile.childElementCount > 1) {
      const mobileArrows = module.querySelectorAll(`.arrow-mobile`);

      mobileArrows.forEach(arrow => {
        arrow.classList.remove(`hidden`);
      });

      sliderElements.controlBarMobile.parentElement.classList.remove(`hidden`);
    }

    const [arrowLeftMobile, arrowRightMobile] = Array.from(
      module.querySelectorAll(`.text-arrow-container > .arrow-mobile`)
    );

    const [arrowLeftDesktop, arrowRightDesktop] = Array.from(
      module.querySelectorAll(`.text-arrow-container .arrow-desktop`)
    );

    arrowLeftMobile.addEventListener(
      `click`,
      () => {
        interval.stop();
        changeSlideInDirection(
          -1,
          sliderElements,
          sliderElements.controlBarMobile,
          true
        );
      },
      { passive: true }
    );
    arrowRightMobile.addEventListener(
      `click`,
      () => {
        interval.stop();
        changeSlideInDirection(
          1,
          sliderElements,
          sliderElements.controlBarMobile,
          true
        );
      },
      { passive: true }
    );

    arrowLeftDesktop.addEventListener(
      `click`,
      () => {
        interval.stop();
        changeSlideInDirection(
          -1,
          sliderElements,
          sliderElements.controlBarDesktopSmall
        );
      },
      { passive: true }
    );
    arrowRightDesktop.addEventListener(
      `click`,
      () => {
        interval.stop();
        changeSlideInDirection(
          1,
          sliderElements,
          sliderElements.controlBarDesktopSmall
        );
      },
      { passive: true }
    );
  };

  const getSliderElements = module => {
    const slides = module.querySelectorAll(`.text-container > .slide`);
    const imageSlides = module.querySelectorAll(`.container-spacing > .slide`);
    const highlightImages = module.querySelectorAll(
      `.container-spacing > .slide`
    );

    const controlBarDesktop = module.querySelector(`.desktop > .controls`);
    const controlElementsDesktop = module.querySelectorAll(
      `.desktop > .controls > li`
    );
    const controlBarDesktopSmall = module.querySelector(
      `.desktop-small > .controls`
    );
    const controlElementsDesktopSmall = module.querySelectorAll(
      `.desktop-small > .controls > li`
    );
    const controlBarMobile = module.querySelector(`.mobile > .controls`);
    const controlElementsMobile = module.querySelectorAll(
      `.mobile > .controls > li`
    );
    const textContainer = module.querySelector(`.text-container`);

    const sliderElements = {
      slides,
      highlightImages,
      controlBarDesktop,
      controlElementsDesktop,
      controlBarDesktopSmall,
      controlElementsDesktopSmall,
      controlBarMobile,
      controlElementsMobile,
      textContainer,
      imageSlides
    };

    return sliderElements;
  };

  class Interval {
    constructor(direction, sliderElements, ms = `4999`) {
      this.ival = {};
      this.direction = direction;
      this.ms = ms;
      this.sliderElements = sliderElements;
    }

    stop() {
      clearInterval(this.ival);
    }

    start() {
      if (parseInt(this.ms, 10) !== 0 && this.ms !== ``) {
        this.ival = setInterval(
          () =>
            changeSlideInDirection(
              this.direction,
              this.sliderElements,
              this.sliderElements.controlBarDesktop
            ),
          this.ms
        );
      }
    }
  }

  function initAnimationDuration(module, s) {
    const elements = module.querySelectorAll(
      `.desktop > .controls > li > .element`
    );

    elements.forEach(element => {
      element.style.animationDuration = `${s}s`;
      Array.from(
        element.querySelectorAll(
          `.top-right, .right, .bottom, .left, .top-left`
        )
      ).forEach(animatedBar => {
        animatedBar.style.animationDuration = `${s}s`;
      });
    });
  }

  function addSwipeHandler(htmlElement, sliderElements, controlBar) {
    const treshold = 140;
    let startPos = [0, 0];

    htmlElement.addEventListener(
      `touchstart`,
      event => {
        const touches = Array.from(event.changedTouches);
        if (touches.length > 1) {
          // Multiple fingers
          return;
        }

        startPos = [touches[0].clientX, touches[0].clientY];
      },
      { passive: true }
    );

    htmlElement.addEventListener(
      `touchend`,
      event => {
        const touches = Array.from(event.changedTouches);
        const endPos = [touches[0].clientX, touches[0].clientY];
        const diffPos = [endPos[0] - startPos[0], endPos[1] - startPos[1]];
        if (touches.length > 1) {
          return;
        }
        if (Math.abs(diffPos[1]) > Math.abs(diffPos[0])) {
          return;
        }

        if (Math.abs(diffPos[0]) < treshold) {
          return;
        }

        // Change Slide
        const direction = diffPos[0] < 0 ? 1 : -1;
        changeSlideInDirection(direction, sliderElements, controlBar);

        startPos = [0, 0];
      },
      { passive: true }
    );
  }

  function changeSlideScroll(slides, index) {
    slides.forEach(slide => {
      slide.style.transform = `translateX(${index * -100}vw)`;
    });
  }

  function initMobileClassListener(slides, imageSlides) {
    const textSlides = Array.from(slides).filter(slide =>
      slide.classList.contains(`text-content`)
    );
    const options = {
      attributes: true
    };
    slides.forEach(slide => {
      const mutOb = new window.MutationObserver(changes => {
        changes.forEach(change => {
          if (
            change.attributeName === `class` &&
            change.target.classList.contains(`active`)
          ) {
            const index = textSlides.findIndex(
              slideH => slideH === change.target
            );
            changeSlideScroll([...slides, ...imageSlides], index);
          }
        });
      });
      mutOb.observe(slide, options);
    });
  }

  function init(module) {
    const sliderElements = getSliderElements(module);
    const ms = module.querySelector(`.slide-content`).dataset.milliseconds;
    const interval = new Interval(1, sliderElements, ms);
    initArrowNav(module, sliderElements, interval);
    initDesktopNav(sliderElements, module);
    initAnimationDuration(module, parseInt(ms, 10) / 1000);

    // select first image (first slide preview)
    const firstImage: HTMLImageElement = module.querySelector(
      `.slide img.first`
    );
    // change opacity of firstImage to 1 when loaded
    if (firstImage !== null) {
      if (firstImage.complete) {
        firstImage.style.opacity = `1`;
      }
      firstImage.addEventListener(`load`, () => {
        firstImage.style.opacity = `1`;
      });
    }

    // Set Callback
    const secondaryImages =
      module.querySelector(`.slide-content`).dataset.usesSecondaryImages ===
      `1`;
    if (secondaryImages) {
      const overlay = module.querySelector(`.divider-gis .image`); // Aktuell nur gis.
      slideChangeCallback = secondaryImagesCallback(overlay);
    }

    initMobileClassListener(sliderElements.slides, sliderElements.imageSlides);
    // initMobileScrollListener(sliderElements);
    interval.start();

    sliderElements.controlElementsDesktop.forEach(controlElement => {
      controlElement.addEventListener(
        `click`,
        event => {
          interval.stop();
          changeSlide(
            event,
            sliderElements,
            sliderElements.controlBarDesktop,
            true
          );
        },
        { passive: true }
      );
    });

    sliderElements.controlElementsMobile.forEach(controlElement => {
      controlElement.addEventListener(
        `click`,
        event => {
          interval.stop();
          changeSlide(
            event,
            sliderElements,
            sliderElements.controlBarMobile,
            true
          );
        },
        { passive: true }
      );
    });

    sliderElements.controlElementsDesktopSmall.forEach(controlElement => {
      controlElement.addEventListener(
        `click`,
        event => {
          interval.stop();
          changeSlide(
            event,
            sliderElements,
            sliderElements.controlBarDesktopSmall,
            true
          );
        },
        { passive: true }
      );
    });

    addSwipeHandler(module, sliderElements, sliderElements.controlBarMobile);
  }

  modules.forEach(module => {
    init(module);
  });
}
