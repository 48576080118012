import { h } from "preact";
import breakpoints from "utils/breakpoints";
import { MediumTGItem } from "./MediumTGItem";

export default function MediumTGGrid({ items, dontStretchTiles }) {
  if (breakpoints.large()) {
    const mapping = {
      "i1-3": 1,
      "s1-3": 1,
      "i2-3": 2,
      "s2-3": 2,
      "i3-3": 3,
      "s3-3": 3
    };

    const upMapping = {
      "i1-3": [null, `i2-3`, `i3-3`],
      "s1-3": [null, `s2-3`, `s3-3`],
      "i2-3": [null, `i3-3`, `i3-3`],
      "s2-3": [null, `s3-3`, `s3-3`],
      "s3-3": [null, `s3-3`, `s3-3`],
      "i3-3": [null, `i3-3`, `i3-3`]
    };

    const valuedTiles = items.map(tile => {
      const value = mapping[tile.tileSize];
      return {
        element: tile,
        value
      };
    });

    const chunked = valuedTiles.reduce(
      (acc, curr) => {
        const currentRow = acc[acc.length - 1];
        const currentRowValue = currentRow.reduce((sum, currentEntry) => {
          return sum + currentEntry.value;
        }, 0);
        if (currentRowValue + curr.value > 3) {
          return [...acc, [curr]];
        }
        acc[acc.length - 1].push(curr);
        return acc;
      },
      [[]]
    );

    const transformedRows = chunked.map(row => {
      const rowValue = row.reduce((acc, curr) => acc + curr.value, 0);
      if (!dontStretchTiles && rowValue < 3) {
        const missing = 3 - rowValue;
        const lastElement = row[row.length - 1].element;
        lastElement.tileSize = upMapping[lastElement.tileSize][missing];
        row[row.length - 1].element = lastElement;
      }
      return row.map(({ element }) => element);
    });

    items = transformedRows.flat();
  }

  let divCssClass = `root-flex`;
  if (dontStretchTiles) {
    divCssClass = `${divCssClass} dont-stretch-last-row`;
  }
  return (
    <article class="new-medium-tile-grid module">
      <div class={divCssClass}>
        {items.map(item => (
          <MediumTGItem {...item} />
        ))}
      </div>
    </article>
  );
}
