/**
 * allImagesLoaded
 * @param {HTMLImageElement[]} images The items to wait for
 * @param {function} callback Execute as soon as all images have status "complete"
 */
const allImagesLoaded = (images, callback) => {
  const imagesLoaded = Array.from(images)
    .map(image => {
      if (!image.complete) {
        return new Promise(resolve => {
          const resolver = () => {
            resolve(true);
          };
          image.onload = resolver;
          image.onerror = resolver;
          image.onabort = resolver;
        });
      }
      return undefined;
    })
    .filter(entry => entry !== undefined);
  Promise.all(imagesLoaded).then(() => {
    callback();
  });
};

export default allImagesLoaded;
