import { h, render } from "preact";

import CookieBanner from "component/cookies/CookieBanner";
import { get as getCookie } from "utils/cookieMethods";

export default function(modules) {
  const init = module => {
    const cookieData = getCookie(`privacy-settings`);
    const data = window.privacyBannerConfig;
    if (cookieData) {
      for (const category of Object.keys(cookieData)) {
        window.allow[category] = cookieData[category];
        if (cookieData[category] && category !== `version`) {
          for (const func of window.function[category]) {
            if (typeof func === `function`) {
              try {
                func();
              } catch (error) {
                // eslint-disable-next-line no-console
                console.warn(`[${category.name}] Error executing: `, func);
              }
            } else {
              // eslint-disable-next-line no-console
              console.warn(`[${category.name}] Is not a function: `, func);
            }
          }
        }
      }
    }
    render(<CookieBanner {...data} module={module} />, module);
  };
  for (const module of modules) {
    init(module);
  }
}
