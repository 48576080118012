import qCopyright from "query/q_copyright";
import qImageSlider from "query/q_image-slider";
import qImageSliderNextgen from "query/q_image-slider-nextgen";
import qImageSliderSyn from "query/q_image-slider-syn";
import qPrivacyNotice from "query/q_privacy-notice";
import qNewMediumTileGrid from "query/q_new-medium-tile-grid";

export const topnavigation = async () => {
  const { default: initilizer } = await import(
    /* webpackMode: 'eager' */ `module/topnavigation`
  );
  initilizer();
};

export const imageSlider = async () => {
  const { instances } = qImageSlider();
  const { default: initilizer } = await import(
    /* webpackMode: 'eager' */ `module/image-slider`
  );
  initilizer(instances);
};

export const imageSliderNextgen = async () => {
  const { instances } = qImageSliderNextgen();
  const { default: initilizer } = await import(
    /* webpackMode: 'eager' */ `module/image-slider-nextgen`
  );
  initilizer(instances);
};

export const imageSliderSyn = async () => {
  const { instances } = qImageSliderSyn();
  const { default: initilizer } = await import(
    /* webpackMode: 'eager' */ `module/image-slider-syn`
  );
  initilizer(instances);
};

export const privacyNotice = async () => {
  const { instances } = qPrivacyNotice();
  const { default: initilizer } = await import(
    /* webpackMode: 'eager' */ `module/privacy-notice`
  );
  initilizer(instances);
};

export const copyright = async () => {
  const { instances } = qCopyright();
  const { default: initilizer } = await import(
    /* webpackMode: 'eager' */ `module/copyright`
  );
  initilizer(instances);
};

export const newMediumTileGrid = async () => {
  const { instances } = qNewMediumTileGrid();
  const { default: initilizer } = await import(
    /* webpackMode: 'eager' */ `module/new-medium-tile-grid`
  );
  initilizer(instances);
};
