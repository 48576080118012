// import { intersectionObserver } from "utils/intersectionObserver";

/* interface QueryFunctionReturn {
  instances: NodeListOf<Element>;
  shouldInit: boolean;
  initName: string;
}

export type QueryFunction = (
  root: HTMLElement | undefined
) => QueryFunctionReturn;
 */
/* eslint-disable no-console */
export default async (query, root: HTMLElement | undefined) => {
  const { instances, shouldInit, initName, ...params } = query(root);
  if (shouldInit) {
    // try {
    // console.log(`Starting to load: ${initName}`);
    const importedScript = import(`module/${initName}`);

    // const options = {
    //   rootMargin: `200px`
    // };
    // const isIntersecting = intersectionObserver(instances, options);

    importedScript
      .then(importedScriptData => {
        // console.log(`Initializer loaded an intersecting: ${initName}`);
        const { default: initilizer } = importedScriptData;

        try {
          initilizer(instances, params);
          console.log(`Initialized: ${initName}`);
        } catch (e) {
          console.error(`Error while INITIALIZING module ${initName}`);
          console.error(e);
        }
      })
      .catch(error => {
        console.error(`Error while LOADING module ${initName}`);
        console.error(error);
      });
    // } catch (e) {
    //   console.error(`Error while LOADING module ${initName}`);
    //   console.error(e);
    //   // Kann vermutlich weg? -> Testen
    // }
  }
};
/* eslint-enable no-console */
