/* eslint-disable */

import { h } from "preact";
import { useState, useRef, useEffect, Ref } from "preact/hooks";
import { getTotalThirds } from "./calculateAccumulatedTileSize";
import { MediumTGItem } from "./MediumTGItem";

export default function MediumTGSlider({ items }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  let [scrollOriginIsClick, setScrollOriginIsClick] = useState(false);
  let [rightMost, setRightMost] = useState(false);
  const scrollAreaRef: Ref<HTMLDivElement|undefined> = useRef();
  const itemRefs = useRef([]);
  const navigator = useRef();

  const rightMostCallback = () => {
    if (scrollAreaRef.current) {
      const { scrollWidth, scrollLeft, offsetWidth } = scrollAreaRef.current;
      setRightMost(scrollWidth - offsetWidth === scrollLeft);
    }
  };

  useEffect(() => {
    if (scrollAreaRef?.current) {
      let lastScrollPos: null|number = null;
      let running = false;
      const scrollArea = scrollAreaRef.current;
      scrollArea.addEventListener(`scroll`, () => {
        if (!running) {
          running = true;
          const getAnimationFrame = () => window.requestAnimationFrame(() => {
            if (lastScrollPos === scrollArea.scrollLeft) { // Stopped  Moving
              rightMostCallback();
              running = false;
            } else {
              lastScrollPos = scrollArea.scrollLeft;
              getAnimationFrame();
            }
          });
          getAnimationFrame();

      }
      });

      if (itemRefs.current.length === items.length) {
        scrollAreaRef.current.scrollTo({
          y: 0,
          x: itemRefs.current[0].base.offsetLeft
        });
      }
    }
  }, [itemRefs, scrollAreaRef]);

  const getIncrementedIndex = (direction, indexBase) => {
    let incremented = null;
    const { scrollWidth, scrollLeft, offsetWidth } = scrollAreaRef.current;
    if (scrollWidth - offsetWidth === scrollLeft && direction === `right`) {
      // We are at rightmost postition. This is not neccesarrily the last item, since 2 or 3 item can be displayed at the same time.
      incremented = 0;
    } else if (direction === `right`) {
      const threshold = offsetWidth + scrollLeft;
      const nextItems = itemRefs.current.slice(indexBase);
      incremented =
        indexBase +
        nextItems.findIndex(item => {
          const offsetRight = item.base.offsetLeft + item.base.offsetWidth;
          return offsetRight > threshold + 1; // Due to rounding im adding 1px MOE.
        });
    } else {
      const threshold = scrollLeft - offsetWidth;
      if (threshold < 0) {
        // The previous Frame does not contain a "full width of items", so its smaller than the width. That means its at the beginning of the scroll area.
        incremented = 0;
      } else {
        const prevItems = itemRefs.current.slice(0, indexBase).reverse();
        const indexOutOfPreviousFrame = prevItems.findIndex(item => {
          const { offsetLeft, offsetWidth } = item.base;
          return offsetLeft - offsetWidth < threshold - 1; // s.o.
        });
        incremented = indexBase - indexOutOfPreviousFrame - 1;
      }
    }
    return incremented;
  };

  const scrollClickHandler = (direction) => () => {
    setScrollOriginIsClick(true);
      const incremented = getIncrementedIndex(direction, currentIndex);
      
      if (incremented >= 0) {
        itemRefs.current[incremented].base.scrollIntoView({
          behavior: `smooth`,
          block: `nearest`,
          inline: `start`
        });
      }
      
      setCurrentIndex(incremented);
    
  };

  // Sum over 1
  const totalThirds = getTotalThirds(items);
  const shouldShowDesktopNav = totalThirds > 3;

  return (
    <article class="new-medium-tile-grid module-full">
      <div class={`navigator ${!shouldShowDesktopNav && "desktop-disabled"}`} ref={navigator}>
        <div class={`arrow-container`}>
          <button
            onClick={scrollClickHandler(`left`)}
            class="arrow left icon-simple-arrow-left disable"
            disabled={currentIndex === 0}
          />
        </div>
        <span class="blocker" />
        <div class="arrow-container">
          <button
            onClick={scrollClickHandler(`right`)}
            class="arrow right icon-simple-arrow-right"
            disabled={rightMost}
          />
        </div>
      </div>
      <div class="mobile-arrow left">
      <div class={`arrow-container`}>
          <button
            onClick={scrollClickHandler(`left`)}
            class="arrow left icon-simple-arrow-left disable"
            disabled={currentIndex === 0}
          />
        </div>
      </div>
      <div class="mobile-arrow right">
      <div class="arrow-container">
          <button
            onClick={scrollClickHandler(`right`)}
            class="arrow right icon-simple-arrow-right"
            disabled={rightMost}
          />
        </div>
      </div>
      <div class="root-flex-slider" ref={scrollAreaRef} >
        {items.map((item, index) => (
          <MediumTGItem
            intersectionRoot={scrollAreaRef.current}
            {...item}
            ref={thisItem => {
              itemRefs.current[index] = thisItem;
            }}
          />
        ))}
      </div>
    </article>
  );
}
