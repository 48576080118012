import { h } from "preact";

export default function Toggle(props) {
  return (
    <div class="setting">
      <div
        onClick={() => {
          if (!props.disabled) {
            props.callback(props.name);
          }
        }}
        class={`slider ${props.disabled ? `disabled` : ``}`}
        data-active={props.enabled}
      >
        <div class="inner" />
      </div>
      <span>{props.text}</span>
    </div>
  );
}
