import breakpoints from "utils/breakpoints";
import debounce from "utils/debounce";

export const getThumbnailSizeName = () => {
  let thumbnailName = `xs`;
  if (breakpoints.extralarge()) {
    thumbnailName = `xl`;
  } else if (breakpoints.large()) {
    thumbnailName = `l`;
  } else if (breakpoints.medium()) {
    thumbnailName = `m`;
  } else if (breakpoints.small()) {
    thumbnailName = `s`;
  }
  return thumbnailName;
};
export const thumbnailChangerInit = () => {
  window.thumbnailConfigs = [];
  const resizeHandler = () => {
    const thumbnailName = getThumbnailSizeName();
    for (const thbCfg of window.thumbnailConfigs) {
      thbCfg.setThumbnail(thumbnailName);
    }
  };
  window.addEventListener(`resize`, debounce(resizeHandler, null, 200));
  resizeHandler();
};

class ThumbnailConfig {
  constructor(htmlItem) {
    this.htmlItem = htmlItem;
    const thumbnailJSONString = htmlItem.dataset.thumbnails;
    if (thumbnailJSONString) {
      try {
        this.thumbnails = JSON.parse(thumbnailJSONString);
        this.setThumbnail(getThumbnailSizeName());
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }

  setThumbnail(thumbnailName) {
    if (this.thumbnails) {
      const srcURL = this.thumbnails[thumbnailName];
      if (srcURL === undefined) return;
      if (this.htmlItem.nodeName === `IMG`) {
        this.htmlItem.src = this.thumbnails[thumbnailName];
      } else {
        this.htmlItem.style.backgroundImage = `url(${this.thumbnails[thumbnailName]})`;
      }
    }
  }
}

export const insertThumbnailConfig = htmlItem => {
  const thbCfg = new ThumbnailConfig(htmlItem);
  if (thbCfg) {
    window.thumbnailConfigs.push(thbCfg);
  }
};
