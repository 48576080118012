export default function() {
  let clientX;
  // const widthArray = [0];
  const widthArray = [];
  const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  let margin = width >= 768 ? 40 : 15;
  margin = width >= 992 ? 96 : margin;
  const instances = document.querySelectorAll(`.company-slider`);

  return {
    instances,
    shouldInit: instances.length > 0,
    initName: `company-slider`,
    clientX,
    widthArray,
    margin
  };
}
