export const base64decode = function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(
        window.atob(str),
        c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      )
      .join(``)
  );
};

export const base64encode = function b64EncodeUnicode(str) {
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );
};
