export default function(modules) {
  function toggleCopyright(module) {
    const copyright = module.querySelector(`.copyright`);
    if (copyright.style.display === `none` || copyright.style.display === ``) {
      copyright.style.display = `block`;
    } else {
      copyright.style.display = `none`;
    }
  }

  function init(module) {
    const copyright = module.querySelector(`.copyright`);
    if (copyright) {
      const toggle = module.querySelector(`.toggle-copyright`);
      toggle.addEventListener(`click`, () => toggleCopyright(module));
      const copyrightElements = document.querySelectorAll(
        `[data-copyright-title]`
      );

      if (copyrightElements.length === 0) {
        module.parentNode.classList.add(`hidden`);
        return;
      }
      module.classList.remove(`hidden`);

      const copyRightInfos = Array.from(copyrightElements).map(item => {
        const {
          copyrightTitle: title = ``,
          copyrightSourceUrl: sourceUrl = ``,
          copyrightSourceText: sourceText = ``,
          copyrightLicenseText: licenseText = ``,
          copyrightLicenseUrl: licenseUrl = ``,
          copyrightDate: date = ``
        } = item.dataset;
        return {
          title,
          sourceUrl,
          sourceText,
          licenseText,
          licenseUrl,
          date
        };
      });
      const uniqueInfos = copyRightInfos.reduce((accu, current) => {
        if (!accu.length) {
          return [current];
        }
        let duplicate = false;
        for (const previousElement of accu) {
          duplicate = Object.keys(current).every(
            key => current[key] === previousElement[key]
          );
          if (duplicate) {
            break;
          }
        }
        return duplicate ? accu : [...accu, current];
      }, []);
      const copyRows = uniqueInfos.map(
        info => `
        <div class="copyright-row">
          <span>${info.title}:</span>
          <a href="${info.sourceUrl}">${info.sourceText}</a>
          <a href="${info.licenseUrl}">${info.licenseText}</a>
        </div>
      `
      );
      for (const row of copyRows) {
        copyright.insertAdjacentHTML(`beforeend`, row);
      }
    }
  }

  modules.forEach(module => {
    init(module);
  });
}
