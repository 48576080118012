export default (func, context, delay) => {
  let inDebounce;
  return args => {
    clearTimeout(inDebounce);
    if (func) {
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    }
    return 1;
  };
};
