export const ratelimit = (func, context, delay) => {
  let lastTime = new Date();
  return args => {
    const now = new Date();
    if (!(now - lastTime < delay)) {
      func.apply(context, args);
      lastTime = now;
    }
  };
};
