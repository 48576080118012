import isIterable from "utils/isIterable";
/**
 * @param {HTMLElement[]} target Array oder Element, auf welche(s) die Listener gelegt werden. Wird als this an die Funktion gegeben.
 * @param {Function} enter Mouse Enter function
 * @param {Function} leave Mouse Leave function
 * @param  {...any} args Beliebige Paramaeter
 */
const hover = (target, enter, leave, ...args) => {
  if (target == null) return;
  const attach = to => {
    if (typeof enter === `function`) {
      to.addEventListener(`mouseenter`, enter.bind(to, ...args));
    }
    if (typeof leave === `function`) {
      to.addEventListener(`mouseleave`, leave.bind(to, ...args));
    }
  };
  if (isIterable(target)) {
    for (const element of target) {
      attach(element);
    }
  } else {
    attach(target);
  }
};

export default hover;
