import {
  copyright,
  imageSlider,
  imageSliderNextgen,
  imageSliderSyn,
  privacyNotice,
  topnavigation,
  newMediumTileGrid
} from "query/eager-queries";

import conditionalImport from "utils/conditionalImport";
import lazyModules from "query/all-queries";

export const initModules = async (inRoot: HTMLElement) => {
  const newPromises = [
    imageSlider(),
    topnavigation(),
    privacyNotice(),
    imageSliderNextgen(),
    imageSliderSyn(),
    copyright(),
    newMediumTileGrid(),
    ...lazyModules.map(query => conditionalImport(query, inRoot))
  ];
  return Promise.all(newPromises);
};
