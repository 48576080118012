import qAccordion from "query/q_accordion";
import qAttribution from "query/q_attribution-flag";
import qBigTileGrid from "query/q_bigtg";
import qButton from "query/q_button";
import qCode from "query/q_code";
import qCompanySlider from "query/q_company-slider";
import qContact from "query/q_contact";
import qContactForm from "query/q_contact-form";
import qContentImageSlider from "query/q_content-image-slider";
import qCounter from "query/q_counter";
import qCustomDropdown from "query/q_custom-dropdown";
import qDetailpage from "query/q_detailpage";
import qForm from "query/q_form";
import qGalleryGrid from "query/q_gallery-grid";
import qGroupServices from "query/q_groupservices";
import qHeroSlider from "query/q_hero-slider";
import qImageAndTextSeparator from "query/q_image-and-text-separator";
import qImageHeaderWithIcons from "query/q_image-header-with-icons";
import qImageSliderMaincontent from "query/q_image-slider-maincontent";
import qImageSliderModern from "query/q_image-slider-modern";
import qImageSliderPks from "query/q_image-slider-pks";
import qLightbox from "query/q_lightbox";
import qLogoSlider from "query/q_logo-slider";
import qMap from "query/q_map";
import qMainHeader from "query/q_main-header";
import qNavbarUnderscore from "query/q_navbar-underscore";
import qOnPageNav from "query/q_onpagenav";
import qOnlyHeadlines from "query/q_only-headlines";
import qParallax from "query/q_parallax";
import qPreactDropdown from "query/q_preact-dropdown";
import qPricing from "query/q_pricing";
import qProfilePage from "query/q_profile-page";
import qQuote from "query/q_quote";
import qReferences from "query/q_references";
import qSearchCombo from "query/q_search-combo";
import qSplit from "query/q_split";
import qStickyMediaSidebar from "query/q_sticky-media-sidebar";
import qStyleSection from "query/q_style-section";
import qTable from "query/q_table";
import qRichTextTable from "query/q_rich-text-table";
import qTabs from "query/q_tabs";
import qTextBild from "query/q_text-bild";
import qTimeline from "query/q_timeline";
import qToTopButton from "query/q_totop";
import qTwoColumnCards from "query/q_twocolumncards";
import qBlogProposalFrom from "./q_blog-proposal-form";
import qEditProfileForm from "./q_edit-profile-form";
import qVCardForm from "./q_vcard-form";
import qUserReactions from "./q_user-reactions";
import qShare from "./q_share";

export default [
  qAccordion,
  qAttribution,
  qBigTileGrid,
  qBlogProposalFrom,
  qButton,
  qCode,
  qCompanySlider,
  qContact,
  qContactForm,
  qContentImageSlider,
  qCounter,
  qCustomDropdown,
  qDetailpage,
  qEditProfileForm,
  qForm,
  qGalleryGrid,
  qGroupServices,
  qHeroSlider,
  qImageAndTextSeparator,
  qImageHeaderWithIcons,
  qImageSliderMaincontent,
  qImageSliderModern,
  qImageSliderPks,
  qLightbox,
  qLogoSlider,
  qMainHeader,
  qMap,
  qNavbarUnderscore,
  qOnlyHeadlines,
  qOnPageNav,
  qParallax,
  qPreactDropdown,
  qPricing,
  qProfilePage,
  qQuote,
  qReferences,
  qSearchCombo,
  qShare,
  qSplit,
  qStickyMediaSidebar,
  qStyleSection,
  qTable,
  qRichTextTable,
  qTabs,
  qTextBild,
  qTimeline,
  qToTopButton,
  qTwoColumnCards,
  qUserReactions,
  qVCardForm
];
