const isIterable = obj => {
  const isIE = !!window.MSInputMethodContext && !!document.documentMode;
  if (obj == null) {
    return false;
  }
  if (isIE) {
    return Array.isArray(obj);
  }
  return typeof obj[Symbol.iterator] === `function`;
};

export default isIterable;
